import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllRecipes = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/recipes',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const savePaymentBill = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/payment-bills',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updatePaymentBill = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/payment-bills/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteRecipe = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/recipes/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllRecipes,
  savePaymentBill,
  deleteRecipe,
  updatePaymentBill,
}

<template>
  <div class="finances">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova conta</span>
    </button>
    <b-table
      :data="allFilterBills"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.name }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.name }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="status"
        label="Status"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <b-taglist style="margin-top: 3px; font-weight: bold">
          <b-tag
            :type="
              props.row.status === 'PAGO'
                ? 'is-success'
                : props.row.status === 'ATRASADO'
                ? 'is-danger'
                : 'is-warning'
            "
            >{{ props.row.status }}</b-tag
          >
        </b-taglist>
      </b-table-column>
      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-success"
          @click.prevent="
            onEdit(props.row)
            isCreateMovementModalActive = true
          "
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ props.row.price }}</small>
                <br v-if="props.row.date" />
                <strong v-if="props.row.date">Data do pagamento:</strong>
                <small v-if="props.row.date">{{
                  $moment(props.row.date).format('DD/MM/YYYY')
                }}</small>
                <br />
                <strong>Data de vencimento:</strong>
                <small>{{
                  $moment(props.row.due_date).format('DD/MM/YYYY')
                }}</small>
                <br />
                <strong>Descrição:</strong>
                <small>{{ props.row.description }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCreateMovementModalActive" :width="640" scroll="keep">
      <div class="card" style="height: 800px">
        <div class="card-content" >
          <div class="content">
            <b-field>
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">Pagamentos</p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content" style="height: 300px; overflow-y: auto">
                  <div class="content">
                    <b-field
                      :label="item"
                      v-for="(item, index) of months"
                      :key="index"
                    >
                      <b-tag
                        :type="
                          verifyPayment(model.paymentBills, index)
                            ? 'is-success'
                            : 'is-danger'
                        "
                        >{{
                          verifyPayment(model.paymentBills, index)
                            ? 'PAGO'
                            : 'PENDENTE'
                        }}</b-tag
                      >
                    </b-field>
                  </div>
                </div>
              </b-collapse>
            </b-field>
            <b-field label="Selecione a data">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="modelMovement.date"
                inline
              >
              </b-datepicker>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="modelMovement.value"
              ></b-input>
            </b-field>
            <b-field>
              <button
                class="button field is-info is-light"
                @click="createMovement"
              >
                <span>Salvar</span>
              </button>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Dia de vencimento *">
              <b-input required v-model="model.due_day"></b-input>
            </b-field>
            <b-field label="Descrição">
              <b-input
                v-model="model.description"
                maxlength="200"
                type="textarea"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/bills/store/service'
import servicePaymentBill from '../features/payment-bills/store/service'
import Bill from '../models/bill'

export default {
  name: 'Bills',
  data() {
    return {
      model: Bill.model,
      modelMovement: { date: null, value: 0, bill_id: null },
      isCardModalActive: false,
      isCreateMovementModalActive: false,
      allFilterBills: [],
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('bills', ['allBills']),
  },
  methods: {
    ...mapActions('bills', ['getAllBills']),
    verifyPayment(payments, index) {
      if (!payments || !(payments instanceof Array)) return false

      for (const item of payments) {
        const paymentMonth = new Date(item.date).getMonth()

        if (paymentMonth === index) {
          return true
        }
      }

      return false
    },
    async init() {
      await this.getAllBills()

      const currentMonth = new Date().getMonth()

      console.log(this.allBills)

      this.allFilterBills = []

      for (const bill of this.allBills) {
        if (bill.paymentBills.length) {
          for (const payment of bill.paymentBills) {
            console.log(payment)
            const paymentDate = new Date(payment.date)
            if (currentMonth === paymentDate.getMonth()) {
              bill.status = 'PAGO'
              console.log('faturado')
            }

            if (!bill.status) {
              paymentDate.setDate(bill.due_day)
              const time = paymentDate.valueOf() - Date.now()
              const days = time / (1000 * 3600 * 24)

              if (days < 0) {
                bill.status = 'ATRASADO'
              } else {
                bill.status = 'PENDENTE'
              }
            }
          }
        } else {
          const paymentDate = new Date()
          paymentDate.setDate(bill.due_day)
          const time = paymentDate.valueOf() - Date.now()
          const days = time / (1000 * 3600 * 24)

          if (days < 0) {
            bill.status = 'ATRASADO'
          } else {
            bill.status = 'PENDENTE'
          }
        }

        this.allFilterBills.push(bill)
      }
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async createMovement() {
      const movement = {
        ...this.modelMovement,
      }

      if (movement.date)
        movement.date = this.$moment(movement.date).format('YYYY-MM-DD')
      movement.value = movement.value.replace('R$ ', '')
      movement.value = movement.value.replace(',', '')

      movement.bill_id = this.model.id

      try {
        const save = movement.id
          ? await servicePaymentBill.updatePaymentBill(movement)
          : await servicePaymentBill.savePaymentBill(movement)

        await this.$success('Conta')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async save() {
      const bill = {
        ...this.model,
      }

      // if (bill.date) bill.date = this.$moment(bill.date).format('YYYY-MM-DD')
      // bill.due_date = this.$moment(bill.due_date).format('YYYY-MM-DD')
      // bill.price = bill.price.replace('R$ ', '')

      try {
        const save = bill.id
          ? await service.updateBill(bill)
          : await service.saveBill(bill)

        await this.$success('Conta')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a conta?')

        if (result.isConfirmed) {
          await service.deleteBill(id)

          await this.$delete('Conta')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>